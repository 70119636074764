<template>
  <div id="app" class="url" style="padding: 15px 0">
    <div>
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px">
          <el-page-header @back="goBack" content="警员管理"> </el-page-header>
        </el-col>
      </el-row>
    </div>
    <div style="margin:10px">
        <el-button
          size="medium"
          type="success"
          @click="adduser"
          >新增员工</el-button
        >
    </div>
    <div>
      <el-table
        border
        :data="
          UserVehicle.filter(
            (data) =>
              !search ||
              data.nm.toLowerCase().includes(search.toLowerCase())
          )
        "
        max-height="800px"
      >
        <el-table-column prop="nm" label="警员号" width="auto">
        </el-table-column>
        <el-table-column prop="abbr" label="姓名" width="150px">
        </el-table-column>
        <el-table-column prop="dl[0].id" label="设备号" width="150px">
        </el-table-column>
        <el-table-column prop="vehiColor" label="联系电话" width="150px">
        </el-table-column>
        <el-table-column prop="nm" label="所属组织" width="150px">
        </el-table-column>
        <!-- <el-table-column prop="parentName" label="状态" width="150px">
        </el-table-column>
        <el-table-column prop="parentName" label="最后上线时间" width="150px">
        </el-table-column> -->
        <el-table-column align="center" width="auto">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="medium"
              placeholder="输入组织名称搜索"
            />
          </template>
          <template slot-scope="scope">
            <el-button
              size="medium"
              type="primary"
              @click="handleClick(scope.row)"
              >查看</el-button
            >
            <el-button
              size="medium"
              type="danger"
              @click="delVehicle(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog title="添加组织" 
        @close="closeUserDialog('userForm')"
       :visible.sync="userDialog"
        width="50%">
        <el-form ref="userForm" :model="userForm" label-width="100px" :rules="userRules">
          <el-row :gutter="12">
            <el-col :span="8">
              <el-form-item label="警员号" prop="vehiIdno">
                <el-input v-model="userForm.vehiIdno"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="设备号" prop="devIdno">
                <el-select v-model="userForm.devIdno">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.id"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col :span="6" >
              <el-form-item>
                <el-button type="primary" @click="submitForm('userForm')">保存</el-button>
                <el-button @click="closeUserDialog('userForm')">关闭</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
   
  </div>
</template>

<script>
import dataStorage from "@/../utils/dataStorage.js";
import BaseData from "@/assets/config/BaseData.js";
import axios from "axios";

export default {
  data() {
    return {
      UserVehicle: [],
      search: "",
      userDialog:false,
      userForm:{
        jsession:dataStorage.getBaseData("videoJsession"),
        vehiIdno:"",      //警员号
        devIdno:"",       // 设备号
        devType:"11",     // 设备类型
        companyName:"",   // 公司名称
        account:"11",     // 主账户
        factoryType:0,    // 厂家类型
      },
      userRules:[],
      options:[]

    };
  },
  created() {
    this.queryUserVehicle();
    this.findDevice()
  },
  methods: {
    adduser(){
      this.userDialog = true
    },
    findDevice() {
      axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_getDeviceStatus.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
          },
        })
        .then((res) => {
          console.log(res);
           this.options = res.data.status
        });
    },
    submitForm(userForm){

    },
    closeUserDialog(userForm){
      this.userDialog = false
      this.$refs[userForm].resetFields()
    },
    queryUserVehicle() {
      axios
        .get(
          "http://" +
            BaseData.videoIP +
            ":" +
            BaseData.videoPorts +
            "/StandardApiAction_queryUserVehicle.action",
          {
            params: {
              jsession: dataStorage.getBaseData("videoJsession"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          let a = res.data.vehicles
          let b = res.data.companys

          for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < b.length; j++) {
              if (i === j) {
                a[i] = Object.assign(a[i], b[j])
              }
            }
          }
          this.UserVehicle = res.data.vehicles;

        });
    },
    delVehicle() {
      this.$confirm('此操作将永久删除此用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios
        .get(
          "http://" +
            BaseData.videoIP +
            ":" +
            BaseData.videoPorts +
            "/StandardApiAction_deleteVehicle.action",
          {
            params: {
              jsession: dataStorage.getBaseData("videoJsession"),
              vehiIdno:row.vehiIdno,
              delDevice:0
            },
          }
        )
        .then((res) => {
          if (res.data.result == 0) {
            this.$message.success("删除成功")
            this.queryUserVehicle()
          }
        });
      }).catch(() => {
          this.$message.info("取消删除")
      });

    },

    handleClick(row) {
      console.log(row);
    },
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
  },
};
</script>

<style lang="scss" scope></style>
